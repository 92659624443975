import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Auth } from "aws-amplify";

import { useTasksByEmployee, useCreateTask } from '../../../utils/crm/use-task';

import { TaskList } from '../components';
import SnackProgress from '../../shared/snack-progress';
import FormDialog from '../../shared/form-dialog';
import TaskForm from '../../task-form';

const FlexDivider = styled.span`
	flex: 1;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 300
  },
  formLabel: {
    marginRight: 0
  },
  createTask:{
    marginLeft: 15
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 16
  },
  timeline: {
    maxHeight: 800,
    overflowY: 'scroll',
    flexDirection: 'column'
  },
  viewMore: {
    maxWidth: 200,
    margin: '10px auto'
  }

}));



const MyTasks = ({ setTriggerFetch, triggerFetch, showCreateButton, setCurToken, curToken, setCurTeamToken }) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState(null);
  const [progress, setProgress] = useState(null);
  const createTask = useCreateTask();
  const [checked, setChecked] = useState(false);
  const [{ tasks, isLoading, nextToken }] = useTasksByEmployee(checked, curToken, triggerFetch);

  const handleSetToken = value => {
    setCurToken(value)
  }

  const handleCreateTask = async values => {

    setProgress({
      text: 'Creating task...',
      severity: 'info'
    });
    setDialog(null);
    try{
      const data = await createTask(values);
     
      setTriggerFetch(prev => !prev);
      setProgress({
        text: 'Done!',
        severity: 'success'
      });
    }catch (e) {
      console.log(e);
      setProgress({
        text: 'An error ocurred while creating task',
        severity: 'error'
      });
    }
    setTimeout(() => setProgress(null), 2000);
  }

  const handleToggleChecked = (event, value) => {
    setCurToken(null)
    setChecked(value)
  }

  return(
    <Paper className={classes.paper}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Typography variant="h6" gutterBottom>My Tasks</Typography>
        <FlexDivider />
        <FormControlLabel className={classes.formLabel}
          value="form"
          control={<Switch color="primary" checked={checked} onChange={handleToggleChecked}/>}
          label="Show all incomplete"
          labelPlacement="end"
        />
        <If condition={showCreateButton}>
          <Button
            className={classes.createTask}
            aria-describedby="create-product"
            variant="contained"
            color="primary"
            onClick={() => setDialog('create-task')}
          >
            Create Task
          </Button>
        </If>
      </Toolbar>
      <Divider />
      <Box className={classes.timeline}>
        <TaskList tasks={tasks} isLoading={isLoading} setTriggerFetch={setTriggerFetch} allTasks={checked}isOwner setCurToken={setCurToken} setOtherToken={setCurTeamToken}/>
        <If condition={nextToken && tasks.length}>
          <Box textAlign="center">
            <Choose>
              <When condition={isLoading}>
                <CircularProgress className={classes.viewMore} />
              </When>
              <Otherwise>
                <Button
                  variant="contained"
                  className={classes.viewMore}
                  onClick={() => handleSetToken(nextToken)}
                >
                  View more
                </Button>
              </Otherwise>
            </Choose>
          </Box>
        </If>
          <FormDialog
          Form={TaskForm}
          id={"create-task"}
          title="Create Task"
          maxWidth={600}
          disableInnerSubmit={true}
          initialValues={{
            assignedTo: {
              id: Auth.user.username,
              name: Auth.user.attributes.name
            }
          }}
          open={dialog === 'create-task'}
          onCancel={() => setDialog(null)}
          onSubmit={handleCreateTask}
          submitLabel="Create"
        />
        <If condition={progress}>
          <SnackProgress progress={progress} />
        </If>
      </Box>
    </Paper>
  )
};

export default MyTasks;
