import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CardContent from '@material-ui/core/CardContent';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import get from 'lodash/fp/get';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";

import FormDialog from '../../../../shared/form-dialog';
import AlertForm from '../../../../shared/alert-form';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    maxWidth: 600,
    '& .MuiCardActions-root': {
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2)
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(1)
    }
  },
  boxIconText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& p': {
      marginLeft: '8px',
    }
  },
  alignRight: {
    paddingRight: '16px',
    justifyContent: 'flex-end !important'
  }
}))
 const TaskView= ({
   open, 
   anchorEl, 
   onClose, 
   task, 
   onEdit, 
   onComplete,
   onDelete,
   disableActions
  }) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState(null);

  const taskId = get('id',task);
  const due_date = get('due_date', task);
  const description = get('description', task);
  const linkType = get('linkType', task);
  const contact = get('contact', task);
  const company = get('company', task);
  const assignedToName = get('assignedTo.name', task);
  const assignedToId = get('assignedTo.id', task);

  return(
    <Popover
      id={task.id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Card className={classes.root}>
        <CardActions disableSpacing>
          <Typography variant="h5" style={{ marginRight: '40px' }}>{task.title}</Typography>
          <Box>
            <If condition={!disableActions}>
              <IconButton aria-label="edit task">
                <EditOutlinedIcon onClick={() => onEdit()} fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete task">
                <DeleteOutlineOutlinedIcon onClick={() => setDialog('delete-task')} fontSize="small" />
              </IconButton>
            </If>
            <IconButton aria-label="close task">
              <CloseIcon onClick={onClose} fontSize="small" />
            </IconButton>
          </Box>
        </CardActions>
        <CardContent>
          <Box className={classes.boxIconText}>
            <AccessTimeIcon fontSize="small" />
            <Typography>{due_date}</Typography>
          </Box>
          <If condition={description}>
            <Box className={classes.boxIconText}>
              <SubjectOutlinedIcon fontSize="small" />
              <Typography>{description}</Typography>
            </Box>
          </If>
          <Choose>
            <When condition={contact}>
              <Box className={classes.boxIconText}>
                <PeopleAltOutlinedIcon fontSize="small" />
                <Typography>{contact.full_name}</Typography>
              </Box>
            </When>
            <When condition={company}>
              <Box className={classes.boxIconText}>
                <BusinessIcon fontSize="small" />
                <Typography>{company.name}</Typography>
              </Box>
            </When>
          </Choose>
          <Box className={classes.boxIconText}>
            <AssignmentIndIcon fontSize="small" />
            <Typography>{assignedToName}</Typography>
          </Box>
        </CardContent>
        <If condition={assignedToId === Auth.user.username}>
          <Divider />
          <CardActions className={classes.alignRight}>
            <Button size="small" color="primary" onClick={() => onComplete(taskId)}>
              Mark Completed
            </Button>
          </CardActions>
        </If>
      </Card>
      <FormDialog
        Form={AlertForm}
        id="delete-task"
        title="Delete Task"
        maxWidth={600}
        open={dialog === "delete-task"}
        onCancel={() => setDialog(null)}
        onSubmit={() => onDelete(taskId)}
        submitLabel="Delete Task"
      /> 
    </Popover>
  )
}

export default TaskView;