import React, { useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/fp/get';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import moment from 'moment';
import Button from '@material-ui/core/Button';
import Link from "next/link";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';


import FormDialog from '../../../shared/form-dialog';
import TaskForm from '../../../task-form';
import { useMarkTaskComplete, useUndoTaskComplete, useUpdateTask, useDeleteTask} from '../../../../utils/crm/use-task';
import SnackProgress from '../../../shared/snack-progress';
import { TaskView } from '../components';

import UndrawEmpty from '../../../../static/images/undraw_empty_search.svg';

const UploadDrawing = styled(UndrawEmpty)`
  margin: auto;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 50%;
  max-height: 100px;
`;

const FlexDivider = styled.span`
	flex: 1;
`;

const Date = styled(Typography)`
  color: ${props => props.pastDue? 'red' : 'rgba(0, 0, 0, 0.54)'}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontWeight: 500
  },
  date: {
    display: 'inline',
  },
  assignee: {
    display: 'inline',
    margin: '0px 10px'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100px',
    alignItems: 'center'
  },
  linkButton: {
    fontSize: '12px',
    // height: 24,
    minHeight: 'unset',
    padding: '2px 8px',
    lineHeight: 'unset',
    margin: '0px 8px',
    // marginLeft: '8px'
  },
  secondRow: {
    marginTop: 50
  },
  taskGrid: {
    flexDirection: 'column',
    // border: '1px solid green'
  },
  primaryRow: {
    flexDirection: 'row',
    // border: '1px solid red',
    display: 'flex',
  },
  secondaryRow: {
    flexDirection: 'row',
    display: 'flex',
    padding: '4px 8px'
  },
  actionButton: {
    fontSize: '12px',
    // height: 24,
    minHeight: 'unset',
    padding: '4px 8px',
    lineHeight: 'unset',
    // margin: '0px 8px',
    marginLeft: '8px'
  },
}));

const Undo = ({handleUndoComplete}) => {

  return(
    <Button color="inherit" size="small" onClick={() => handleUndoComplete()}>
      UNDO
    </Button>
  );
}

const TaskList = ({ tasks, setTriggerFetch, setCurToken, isLoading, isOwner, showLink, allTasks, setOtherToken }) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState(null);
  const [progress, setProgress] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedTask, setSelectedTask] = useState({})
  const completeTask = useMarkTaskComplete();
  const undoCompleteTask = useUndoTaskComplete();
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();

  const getDeadline = (date) => {
    const daysRemaining = moment(date).diff(moment({hours: 0}),'days');
    // const dueDate = daysRemaining < 0 ? moment(date).calendar({
    //                                         // sameDay: '[Today]',
    //                                         // nextDay: '[Tomorrow]',
    //                                         // nextWeek: 'dddd MM/DD',
    //                                         lastDay: '[Past due yesterday]',
    //                                         lastWeek: '[Past due last] dddd',
    //                                         sameElse: `[${Math.abs(daysRemaining)} days past due] • MM/DD/YYYY`
    //                                     }) :
    //                 daysRemaining === 0 ? 'Due today' :
    //                 daysRemaining === 1 ? `${daysRemaining} day remaining` :
    //                 `${daysRemaining} days remaining`;
    // return dueDate;
    return { 
      label: moment(date).calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd MM/DD',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'dddd MM/DD/YYYY'
      }),
      pastDue: Boolean(moment(date).diff(moment({hours: 0}),'days') < 0)
    };
  }

  const viewTask = (e,task) => {
    setAnchorEl(e.currentTarget);
    setSelectedTask({
      ...task
    });
  }

  const viewEditTask = () => {
    setAnchorEl(null);
    setDialog('edit-task')
  }

  const handleEditTask = async values => {
    setProgress({
      text: 'Updating task...',
      severity: 'info'
    });
    setDialog(null);
    try{
      const data = await updateTask(values);
      setProgress({
        text: 'Done!',
        severity: 'success'
      })
    }catch (e) {
      console.log(e);
      setProgress({
        text: 'An error ocurred while creating task',
        severity: 'error'
      });
    }
    setTimeout(() => {
      setProgress(null);
      setCurToken(null);
      setOtherToken(null);
      setTriggerFetch(prev => !prev);
    }, 2000);
  }

  const handleUndoComplete = async id => {
    setProgress({
      text: 'Undo task completed...',
      severity: 'info'
    });
    try{
      const data = await undoCompleteTask(id);
      
      setProgress({
        text: 'Done!',
        severity: 'success'
      });
    } catch(e) {
      console.log(e);
      setProgress({
        text: 'An error ocurred while undoing',
        severity: 'error'
      });
    }
    setTimeout(() => {
      setProgress(null);
      setCurToken(null)
      setOtherToken(null);
      setTriggerFetch(prev => !prev);
    }, 2000);
  }

  const handleCompleteTask = async id => {
    setAnchorEl(null);
    setProgress({
      text: 'Marking task complete...',
      severity: 'info'
    });
    try{
      const data = await completeTask(id);
      setProgress({
        text: 'Task Completed!',
        severity: 'success',
        action: <Undo handleUndoComplete={() => handleUndoComplete(id)}/>
      });
    } catch(e) {
      console.log(e);
      setProgress({
        text: 'An error ocurred while completing task',
        severity: 'error'
      });
    }
    setTimeout(() => {
      setProgress(null);
      setCurToken(null);
      setOtherToken(null);
      setTriggerFetch(prev => !prev)
    }, 3000);
  }

  const handleDeleteTask = async id => {
    setAnchorEl(null);
    setProgress({
      text: 'Deleting task...',
      severity: 'info'
    });
    try{
      await deleteTask(id);
      setProgress({
        text: 'Task Deleted!',
        severity: 'success'
      });
    }catch(e){
      console.log(e);
      setProgress({
        text: 'An error ocurred while deleting task',
        severity: 'error'
      });
    }
    setTimeout(() => {
      setProgress(null);
      setCurToken(null);
      setOtherToken(null);
      setTriggerFetch(prev => !prev)
    }, 3000);
  }

  if(isLoading && !tasks.length){
    return(
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    )
  }

  if(!isLoading && !tasks.length){
    if (allTasks){
      return(
        <Box className={classes.loading}>
          <UploadDrawing />
          <Typography align="center">
            No tasks found.
          </Typography>
        </Box>
      )
    }
    else if (!allTasks){
      return(
        <Box className={classes.loading}>
          <UploadDrawing />
          <Typography align="center">
            No tasks due today.
          </Typography>
        </Box>
      )
    }
  }
  

  return(
    <List>
      <For each="task" of={tasks}>
        <ListItem>
          <Grid container className={classes.taskGrid}>
            <Grid item xs={12} className={classes.primaryRow}>
              <Typography variant="body1" className={classes.title}>{task.title}</Typography>
              <FlexDivider />
              <Button color="primary" className={classes.actionButton} onClick={(e) => viewTask(e,task)}>View</Button>
              <If condition={isOwner}>
                <Button color="primary" className={classes.actionButton}onClick={() => handleCompleteTask(task.id)}>Complete</Button>
              </If> 
            </Grid>
            <Grid item xs={12}>
              <Date
                component="span"
                variant="body2"
                className={classes.date}
                pastDue={getDeadline(task.due_date).pastDue}
                >
                  {getDeadline(task.due_date).label}
              </Date>
              <If condition={!isOwner}>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.assignee}
                  color="textSecondary"
                >
                Assigned to {get('assignedTo.name', task)}
                </Typography>
              </If>
              <If condition={showLink}>
                <If condition={task.linkType}>
                  <Choose>
                    <When condition={task.linkType === 'CONTACT' && task.contact}>
                      <Link 
                        href={{ pathname: '/customers/contact/[id]' }} 
                        as={`/customers/contact/${get("contact.id", task)}`} 
                        passHref 
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.linkButton}
                          // style={{ margin: '0px 10px', height: 20}}
                        >
                          {get('contact.full_name', task)}
                        </Button>
                      </Link>
                    </When>
                    <When condition={task.linkType === 'COMPANY' && task.company}>
                      <Link 
                        href={{ pathname: '/customers/company/[id]' }} 
                        as={`/customers/company/${get('company.id', task)}`} 
                        passHref 
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.linkButton}
                        >
                          {get('company.name', task)}
                        </Button>
                      </Link>
                    </When>
                  </Choose>
                </If>
              </If>
            </Grid>
          </Grid>
        </ListItem>
        <Divider component="li" />
      </For>
      <FormDialog
        Form={TaskForm}
        id={"edit-task"}
        title="Edit Task"
        maxWidth={600}
        disableInnerSubmit={true}
        initialValues={selectedTask}
        open={dialog === 'edit-task'}
        onCancel={() => setDialog(null)}
        onSubmit={handleEditTask}
        submitLabel="Save"
      />
      <If condition={progress}>
        <SnackProgress progress={progress} />
      </If>
      <TaskView
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        task={selectedTask}
        onEdit={viewEditTask}
        onClose={() => setAnchorEl(null)}
        onComplete={handleCompleteTask}
        onDelete={handleDeleteTask}
      />
    </List>
  )
};

TaskList.defaultProps = {
  showLink: true
}

export default TaskList;