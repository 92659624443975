import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import concat from 'lodash/fp/concat';

import { useTasksBySelectedEmployee } from '../../../utils/crm/use-task';
import { useEmployees } from '../../../utils/crm/use-employee';

import { TaskList } from '../components';
import SortBySelector from '../../shared/sort-by-selector';

const FlexDivider = styled.span`
	flex: 1;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 300
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 16
  },
  timeline: {
    maxHeight: 800,
    overflowY: 'scroll',
    flexDirection: 'column'
  },
  viewMore: {
    maxWidth: 200,
    margin: '10px auto'
  }
}));



const TeamTasks = ({ setTriggerFetch, triggerFetch, setCurToken, curToken, setCurMyToken }) => {
  const classes = useStyles();
  const [employee, setEmployee] = useState('All');
  const [{ tasks, isLoading, nextToken }] = useTasksBySelectedEmployee(employee, curToken, triggerFetch);
  const [{ employees }] = useEmployees();
  let options = concat([{ name: "All", id: "All" }], employees);
  const handleFilterEmployee = value => {
    setCurToken(null)
    setEmployee(value)
  };

  return(
    <Paper className={classes.paper}>
      <Toolbar disableGutters className={classes.toolbar}>
        <Typography variant="h6" gutterBottom>Team Tasks</Typography>
        <FlexDivider />
        <SortBySelector
          label="Employees"
          handleSort={handleFilterEmployee}
          options={options}
          optionLabel="name"
          optionValue="id"
          initialValue={options[0].id}
        />
      </Toolbar>
      <Divider />
      <Box className={classes.timeline}>
        <TaskList tasks={tasks} setTriggerFetch={setTriggerFetch} isLoading={isLoading} setCurToken={setCurToken} setOtherToken={setCurMyToken}/>        
        <If condition={nextToken && tasks.length}>
          <Box textAlign="center">
            <Choose>
              <When condition={isLoading}>
                <CircularProgress className={classes.viewMore} />
              </When>
              <Otherwise>
                <Button
                  variant="contained"
                  className={classes.viewMore}
                  onClick={() => setCurToken(nextToken)}
                >
                  View more
                </Button>
              </Otherwise>
            </Choose>
          </Box>
        </If>
      </Box >
    </Paper>
  )
};

export default TeamTasks;
