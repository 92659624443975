import React, {useState} from "react";
import styled from 'styled-components';
import { Form, Field as FinalField, FormSpy } from 'react-final-form';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import get from "lodash/fp/get";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputLabel from "@material-ui/core/InputLabel";

import InputField from "../shared/input-field";
import DateField from '../shared/date-field';
import ContactSelectField from '../contact-select-field';
import CompanySelectField from '../company-select-field';
import EmployeeSelectField from '../shared/employee-select-field';

const Field = ({ item, xs, sm, md, ...props }) => (
  <Grid item xs={xs} sm={sm} md={md}>
      <FinalField {...props} />
  </Grid>
);


const required = value => (value ? undefined : 'Required');

const TaskForm = ({
  onSubmit,
  id,
  initialValues,
  labelSubmit,
}) => {
  const [linkType, setLinkType] = useState(initialValues.linkType);
  const clear = ([name], state, { changeValue }) => {
    changeValue(state, name, () => null)
  };

  const handleToggle = (event, value, form, input) => {
      input.onChange(value);
      setLinkType(value);
  }

  return(
    <Form onSubmit={onSubmit} initialValues={initialValues} mutators={{ clear }}>
      {({ handleSubmit, form }) => (
        <Box onSubmit={handleSubmit} component="form" padding={1} id={id} paddingBottom={4} minWidth={300} maxWidth={600}>
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              if(linkType === 'COMPANY') values.contact = null;
              if(linkType === 'CONTACT') values.company = null;
            }}
          />
          <Grid container spacing={2} wrap>
          <Field
            sm={12}
            component={InputField}
            name="title"
            fullWidth
            label="Title *"
            validate={required}
          />
          <Field
            sm={6}
            component={DateField}
            name="due_date"
            fullWidth
            allowNull
            label="Due Date *"
            form={form}
            validate={required}
            disablePast
            parse={value => value != null ? `${value.toISOString(true).slice(0,11)}00:00` : ''}
          />
          <Field
            sm={6}
            component={EmployeeSelectField}
            name="assignedTo"
            label="Assigned to"
            marginTop="8px"
            getOptionLabel={get("name")}
            getOptionValue={get("id")}
            validate={required}
          />
          <Field
           sm={3}
           name="linkType"
          >
            {({ input, meta }) => (
              <React.Fragment>
                <InputLabel shrink>Link</InputLabel>
                <ToggleButtonGroup size="small" color="primary" style={{marginTop: 12}} exclusive value={input.value} onChange={(e,v) => handleToggle(e,v,form, input)}>
                  <ToggleButton value="CONTACT" selected={input.value === 'CONTACT'} >Contact</ToggleButton>
                  <ToggleButton value="COMPANY" selected={input.value === 'COMPANY'} >Company</ToggleButton>
                </ToggleButtonGroup> 
              </React.Fragment>
            )}
           </Field>
          <Choose>
            <When condition={linkType === 'CONTACT'}>
              <Field
                sm={9}
                component={ContactSelectField}
                name="contact"
                label="Contact"
                marginTop="8px"
                getOptionLabel={get("full_name")}
                getOptionValue={get("id")}
              />
            </When>
            <When condition={linkType === 'COMPANY'}>
              <Field
                sm={9}
                component={CompanySelectField}
                name="company"
                label="Company"
                marginTop="8px"
                getOptionLabel={get("name")}
                getOptionValue={get("id")}
              />
            </When>
          </Choose>
          <Field
            sm={12}
            component={InputField}
            name="description"
            fullWidth
            label="Description"
            multiline
            rows={6}
          />  
          </Grid>
        </Box>
      )}
    </Form>
  );
};

export default TaskForm;