import React, {useState} from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import DateRangeIcon from '@material-ui/icons/DateRange';
import FormHelperText from "@material-ui/core/FormHelperText";

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";

const DateInputContainer = ({
  label,
  placeholder,
  input,
  inputProps,
  meta: { touched, invalid, error },
  name,
  form,
  className,
  fullWidth,
  ...custom
}) => {

  const [isFocus, setFocus] = useState(false);
  const hasError = touched && !!error;
return(
  <FormControl error={hasError} fullWidth={fullWidth}>
    <InputLabel shrink htmlFor={'id_' + name}>{label}</InputLabel>
    <Box
       border={1}
       borderColor={isFocus ? "primary.main" : hasError ? "error.main" : "#c4c4c4"}
       paddingLeft={1}
       paddingRight={1}
       width={1}
       onFocus={() => setFocus(true)}
       onBlur={() => setFocus(false)}
       display="flex"
       flexDirection="column"
       marginTop="24px"
       height={50}
       borderRadius={2}
       justifyContent="center"
     >
     <InputBase
      placeholder={placeholder}
      {...input}
      {...custom}
      inputProps={{
        ...inputProps,
        name,
        id: 'id_' + name,
      }}
      endAdornment={ input.value ? (
        (
           <IconButton 
              size="small"
               children={<ClearIcon fontSize="small" />} 
               padding={0}
               onClick={(e) => {
                   e.stopPropagation();
                   form.mutators.clear(name);
               }} 
           />
       )
      ): <DateRangeIcon />
      }
      />
     </Box>
     <FormHelperText id={`${name}-helper-text`}>
        <Choose>
          <When condition={hasError}>{error}</When>
          <When condition={custom.helperText}>{custom.helperText}</When>
        </Choose>
      </FormHelperText>
  </FormControl>
);
}


const DateField = ({ input: { onBlur, onChange, name, value }, form, disablePast, ...props}) => {
  // console.log(props);
  return(
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
          variant="inline"
          id={name}
          value={value}
          onChange={onChange}
          onClose={onBlur}
          style={{zIndex: 0}}
          orientation="landscape"
          allowKeyboardControl
          disableFuture={props.disableFuture}
          disablePast={disablePast}
          format="L"
          autoOk
          TextFieldComponent={
            ({ inputProps, onClick, inputRef }) => 
            <DateInputContainer
              {...props}
              placeholder="mm/dd/yyyy"
              name={name}
              form={form}
              format="L"
              input={{value: value? moment(value).format('L'): '' }}
              inputProps={inputProps}
              onClick={onClick}
              inputRef={inputRef}
            />
          }
      />
  </MuiPickersUtilsProvider>
  )
};

export default DateField;