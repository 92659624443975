import {useState, useEffect } from 'react';
import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { Auth } from 'aws-amplify';
import get from "lodash/fp/get";
import pickBy from "lodash/pickBy";
import identity from 'lodash/identity';
import moment from 'moment';

export const useCreateTask = () => {
  const { createTask } = useActions(actions);
  return async values => {
    const user = await Auth.currentAuthenticatedUser();
    const input = {
      title: values.title,
      due_date: values.due_date.slice(0,10),
      description: get('description', values),
      linkType: get('company',values) || get('contact', values) ? values.linkType : null,
      creatorId: user.username,
      taskAssignedToId: get('assignedTo.id', values),
      taskCompanyId: get('company.id', values),
      taskContactId: get('contact.id', values),
      employeeId: get('assignedTo.id', values),
      status: 'TODO'
    }

    const data = await createTask({ input: pickBy(input, identity)})
    return data;
  }
}

export const useTasksByEmployee = (checked, token, triggerFetch) => {
  const { getTasksByEmployee } = useActions(actions);
  const [tasks, setTasks] = useState([])
	const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken ] = useState(null);

  const fetchTasks = async() => {
    setIsLoading(true);
    try{
      const { username: currentEmployeeId } = await Auth.currentAuthenticatedUser();
      
      if (!checked){
        const data = await getTasksByEmployee({
          employeeIdDue_date: {
            beginsWith: {
              employeeId: currentEmployeeId,
            },
          },
          filter:{
            due_date:{
              le: moment(new Date()).format("YYYY-MM-DD")
            }
          },
          limit: 10,
          nextToken: token,
          status: 'TODO',
          sortDirection: 'ASC'
        });
        if (token){
          setTasks(prev=> prev.concat(data.value.listTasksByAssignedTo.items))
        }
        else{
          setTasks(data.value.listTasksByAssignedTo.items);
        }
        
        setNextToken(data.value.listTasksByAssignedTo.nextToken);
      }
      else{
        const data = await getTasksByEmployee({
          employeeIdDue_date: {
            beginsWith: {
              employeeId: currentEmployeeId,
            },
          }, 
          limit: 10,
          nextToken: token,
          status: 'TODO',
          sortDirection: 'ASC'
        });
        if (token){
          setTasks(prev=> prev.concat(data.value.listTasksByAssignedTo.items))
        }
        else{
          setTasks(data.value.listTasksByAssignedTo.items);
        }
        
        setNextToken(data.value.listTasksByAssignedTo.nextToken);
      }
      
    }catch(e){
      console.log(e);
    }
    setIsLoading(false);
  }

  useEffect(() => { fetchTasks() }, [checked, triggerFetch, token]);
  return[{ tasks, isLoading, nextToken}]
}

export const useTasksBySelectedEmployee = (id, token, triggerFetch) => {
  const { getTasksByEmployee } = useActions(actions);
  const { getTasksByDueDate } = useActions(actions);

  const [tasks, setTasks] = useState([])
	const [isLoading, setIsLoading] = useState(false);
  const [nextToken, setNextToken ] = useState(null);
  const fetchTasks = async() => {
    setIsLoading(true);
    try{
      if(id === 'All') {
        const data = await getTasksByDueDate({
          limit: 10,
          nextToken: token,
          status: 'TODO',
          sortDirection: 'ASC'
        });
        if (token) {
          setTasks(prev => prev.concat(data.value.listTasksByDueDate.items))
        }
        else{
          
          setTasks(data.value.listTasksByDueDate.items)
        }
        setNextToken(data.value.listTasksByDueDate.nextToken);

      }
      else {
        const data = await getTasksByEmployee({
          employeeIdDue_date: {
            beginsWith: {
              // due_date: moment(new Date()).format("YYYY-MM-DD"),
              employeeId: id
            }
          },
          limit: 10,
          nextToken: token,
          status: 'TODO',
          sortDirection: 'ASC'
        });
        
        if (token) {
          setTasks(prev => prev.concat(data.value.listTasksByAssignedTo.items))
        }
        else{
          setTasks(data.value.listTasksByAssignedTo.items)
        }
        setNextToken(data.value.listTasksByAssignedTo.nextToken);
      }
    }catch(e){
      console.log(e);
    }
    setIsLoading(false);
  }
  useEffect(() => { fetchTasks() }, [id, triggerFetch ,token]);
  return[{ tasks, isLoading, nextToken}]
}

export const useMarkTaskComplete = () => {
  const { updateTask } = useActions(actions);

  return async id => {
    try {
      return await updateTask({
        input: {
          id,
          status: 'COMPLETED'
        }
      });
    } catch(e){
      console.log(e);
    }
  }
}

export const useUndoTaskComplete = () => {
  const { updateTask } = useActions(actions);

  return async id => {
    try {
      return await updateTask({
        input: {
          id,
          status: 'TODO'
        }
      });
    } catch(e){
      console.log(e);
    }
  }
}

export const useUpdateTask = () => {
  const { updateTask } = useActions(actions);

  return async values => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const input = {
        id: values.id,
        title: values.title,
        due_date: values.due_date.slice(0,10),
        description: get('description', values),
        linkType: get('company',values) || get('contact', values) ? values.linkType : null,
        creatorId: user.username,
        taskAssignedToId: get('assignedTo.id', values),
        taskCompanyId: get('company.id', values),
        taskContactId: get('contact.id', values),
        employeeId: get('assignedTo.id', values),
      }
  
      const data = await updateTask({ input: pickBy(input, identity)});
      return data;
    } catch(e){
      console.log(e);
    }
  }
}

export const useDeleteTask = () => {
  const { deleteTask } = useActions(actions);
  return async id => {
    try{
      await deleteTask({ input: {id}})
    } catch(e){
      console.log(e)
    }
  }
}